/* eslint-disable no-undef */
const isCryptoSupported = !!window.crypto;

const isInsertableStreamsSupported = !!RTCRtpSender.prototype.createEncodedStreams
|| !!RTCRtpSender.prototype.createdEncodedVideoStreams;

const isWebWorkerSupported = !!window.Worker;

const isE2eeSupported = () =>
  isCryptoSupported && isInsertableStreamsSupported && isWebWorkerSupported;

module.exports = (deps = {}) => {
  /* eslint-disable-next-line global-require */
  const hasOpenTokSupport = deps.hasOpenTokSupport || require('./hasOpenTokSupport').once;
  const hasE2eeCapability = deps.isE2eeSupported || isE2eeSupported;

  return hasOpenTokSupport() && hasE2eeCapability();
};
