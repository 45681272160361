/* eslint-disable no-underscore-dangle */
const EventEmitter = require('events');
const logging = require('../../helpers/log')('AudioFallbackState');

const AudioFallbackVideoStates = {
  ACTIVE_VIDEO: 'activeVideo',
  ACTIVE_VIDEO_WITH_WARNING: 'activeVideoWithWarning',
  SUSPENDED_VIDEO: 'suspendedVideo',
};

const CongestionLevels = {
  LOW: 'low',
  MEDIUM: 'medium',
  CRITICAL: 'critical',
};

class AudioFallbackStateMachine extends EventEmitter {
  constructor() {
    super();
    this._setInitialState();
    this._transitions = {
      [CongestionLevels.LOW]: this._transitionToLow,
      [CongestionLevels.MEDIUM]: this._transitionToMedium,
      [CongestionLevels.CRITICAL]: this._transitionToCritical,
    };
  }

  setCongestionLevel(level) {
    if (this.congestionLevel === level) {
      logging.warn(`Congestion level already set: ${level}`);
      return;
    }
    const transition = this._transitions[level];
    if (transition) {
      transition();
    } else {
      logging.warn(`Invalid congestion level: ${level}`);
    }
  }

  _setInitialState() {
    this.congestionLevel = CongestionLevels.LOW;
    this.videoState = AudioFallbackVideoStates.ACTIVE_VIDEO;
  }

  reset() {
    this._setInitialState();
  }

  _setVideoState = (state) => {
    const previousState = this.videoState;
    this.videoState = state;
    this.emit('stateChange', { previousState, state });
  }

  _transitionToLow = () => {
    this.congestionLevel = CongestionLevels.LOW;
    this._setVideoState(AudioFallbackVideoStates.ACTIVE_VIDEO);
  }

  _transitionToMedium = () => {
    if (this.congestionLevel === CongestionLevels.LOW) {
      this._setVideoState(AudioFallbackVideoStates.ACTIVE_VIDEO_WITH_WARNING);
    }
    this.congestionLevel = CongestionLevels.MEDIUM;
  }

  _transitionToCritical = () => {
    this.congestionLevel = CongestionLevels.CRITICAL;
    this._setVideoState(AudioFallbackVideoStates.SUSPENDED_VIDEO);
  }
}

module.exports = {
  AudioFallbackStateMachine,
  AudioFallbackVideoStates,
  CongestionLevels,
};
